import getVideoId from 'get-video-id'
import Lightbox from './Lightbox'
import ListenerManager from './listenerManager'

export default class MediaLightbox {
	#listenerBoss
	#lightbox
	#selectors
	#iframeTestStrings

	constructor({
		selectors = "a[href*='youtu.be'], a[href*='youtube.com'], a[href*='youtube-nocookie.com'], a[href*='vimeo.com'], a[href$='.gif'], a[href$='.jpg'], a[href$='.png'], a[href*='.gif?'], a[href*='.jpg?'], a[href*='.png?'], a[data-iframe]",
		iframeTestStrings = ['youtu.be', 'youtube.com', 'youtube-nocookie.com', 'vimeo.com', 'matterport.com'],
		baseClass = 'lightbox',
		closeSelectorAttr = 'data-close',
		onClose = function () {},
		onHtmlSetUp = function (el) {},
		onOpen = function () {},
	} = {}) {
		this.#listenerBoss = new ListenerManager()
		this.#lightbox = new Lightbox({ baseClass, closeSelectorAttr, onClose, onOpen, onHtmlSetUp })
		this.#selectors = selectors
		this.#iframeTestStrings = iframeTestStrings
	}

	init() {
		this.#listenerBoss.removeAll()

		let openers = document.querySelectorAll(this.#selectors)
		if (openers.length) {
			openers.forEach(opener => {
				this.#listenerBoss.register(
					opener,
					'click',
					e => {
						e.preventDefault()

						//get the info
						let source = opener.getAttribute('href')

						//determine if this is a video
						let isIframe = false
						this.#iframeTestStrings.forEach(videoTestString => {
							if (source.indexOf(videoTestString) > -1) {
								isIframe = true
							}
						})

						//return focus to this element on close
						this.#lightbox.returnFocusOnClose = opener

						//get the width and height
						let width = opener.hasAttribute('data-width')
							? parseInt(opener.getAttribute('data-width'))
							: null
						let height = opener.hasAttribute('data-height')
							? parseInt(opener.getAttribute('data-height'))
							: null

						if (isIframe) {
							//if this is youtube or vimeo, let's use the best embed syntax
							let { id, service } = getVideoId(source)
							if (id) {
								if (service === 'youtube') {
									source = `https://www.youtube-nocookie.com/embed/${id}?feature=oembed&rel=0showinfo=0&modestbranding=1&disablekb=1&autoplay=1`
								} else if (service === 'vimeo') {
									source = `https://player.vimeo.com/video/${id}?autoplay=1`
								}
							}

							//open the iframe
							this.#lightbox.openMedia(source, 'iframe', { width, height })
						} else {
							//get the alt text
							let alt = opener.getAttribute('alt') ?? ''

							//open the img
							this.#lightbox.openMedia(source, 'img', {
								alt,
								width,
								height,
							})
						}
					},
					{ passive: true },
				)
			})
		}
	}

	close() {
		this.#lightbox.close()
	}
}
