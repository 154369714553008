/*
 * Responsive Elements
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2020
 */

//all of the watched videos
import ResponsiveElement from './ResponsiveElement'

let watchedEls = []

export default {
	init(
		selector = "iframe[src*='//player.vimeo.com']:not(.not-responsive), iframe[src*='//www.youtube.com']:not(.not-responsive), iframe[src*='//www.youtube-nocookie.com']:not(.not-responsive), object:not(.not-responsive), embed:not(.not-responsive)",
		wrapperClass = 'video-player',
		ratioAttr = 'data-aspect-ratio',
	) {
		let els = document.querySelectorAll(selector)
		els.forEach(el => {
			let resEl = new ResponsiveElement(el, { wrapperClass, ratioAttr })
			watchedEls.push(resEl)
		})
	},
	destroy() {
		watchedEls.forEach(watchedEl => {
			watchedEl.destroy()
		})
		watchedEls = []
	},
}
