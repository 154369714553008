import debounce from 'debounce'
import ListenerManager from './listenerManager'

let listenerBoss = new ListenerManager()
let initAttribute = 'data-video-ready'

export default {
	init(selector = '[data-video-cover-holder]') {
		let holderEls = document.querySelectorAll(selector)
		holderEls.forEach(holderEl => {
			if (!holderEl.hasAttribute(initAttribute)) {
				//add the attribute
				holderEl.setAttribute(initAttribute, 'y')

				let videoEl = holderEl.querySelector('video, iframe')
				let videoRatio = (holderEl.getAttribute('data-width') * 1) / holderEl.getAttribute('data-height')

				videoEl.style.height = ''
				videoEl.style.minHeight = 'auto'

				const resizeVideo = () => {
					//adjust the video width if necessary
					videoEl.style.width = holderEl.offsetHeight * videoRatio + 'px'
				}

				//watch
				;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
					listenerBoss.register(window, eventName, debounce(resizeVideo, 10, false))
				})
				resizeVideo()
			}
		})
	},
	destroy() {
		listenerBoss.removeAll()
	},
}
