// ---------- Variables ----------
const scrollSpeed = 500
const scrollOffset = 0
const scrollHeader = '[data-scroll-header]'
const html = document.querySelector('html')
const introIsOpenClass = 'intro--isOpen'
let openIntroCount = 0
const modalIsOpenClass = 'modal--isOpen'
let openModalCount = 0
const lightBoxIsOpenClass = 'lightbox--isOpen'

import isIE11 from './Utils/isIE11'

// ---------- update HTML classes ----------
import './Components/jsClass'
import './Components/ie11Class'

// ---------- polyfills / accessibility ----------
import './Utils/makeEventListenersPassive'
import 'element-qsa-scope'
import 'focus-visible/dist/focus-visible'
import focusWithin from 'focus-within'
import 'svgxuse'
import 'wicg-inert/dist/inert'
import 'keyboardFocus.js/src/keyboard-focus'

// ---------- lazysizes ----------
import lazySizes from 'lazysizes'
//import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
Object.assign(lazySizes.cfg, { loadMode: 1 })

// ---------- smooth scroll ----------
import SmoothScrollLinks from './Components/smoothScroll'
SmoothScrollLinks.init(scrollSpeed, scrollOffset, scrollHeader)

// ---------- floating header ----------
import floating from './Components/floatingElement'
floating.init()

// ---------- global elements and classes ----------
const holderEl = document.querySelector('.holder')
const header = document.querySelector('.header')
const navEl = document.querySelector('.header-menu')
const navToggleEl = document.querySelector('.header-toggle')
let previouslyActiveEl = false
const navOpenClass = 'menu--open'

// ---------- mobile menu ----------
import MobileMenu from './Components/mobileMenu'
const nav = new MobileMenu({
	openClass: 'menu--isOpen',
	closeClass: 'menu--isClosed',
	closeSelector: '[data-nav-close]',
	toggleSelector: '[data-nav-toggle]',
	onOpen() {
		if (navEl) {
			//make the nav el not inert
			navEl.inert = false

			//add the nav open class (used for transitions)
			navEl.classList.add(navOpenClass)
		}

		//set the previously active item
		previouslyActiveEl = document.activeElement

		//update the toggles for accessibility
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', true)

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Close menu')
		})

		//update the closers for accessibility
		this.mobileMenuClosers.forEach(mobileMenuCloser => {
			//set the aria expanded attributes
			mobileMenuCloser.setAttribute('aria-expanded', true)

			//set the aria label attribute
			mobileMenuCloser.setAttribute('aria-label', 'Close menu')

			//make sure it's not hidden
			mobileMenuCloser.removeAttribute('hidden')
		})

		//focus the nav logo
		navToggleEl.focus()
	},
	onClose() {
		if (navEl) {
			//make the nav el not inert
			navEl.inert = true

			//remove the nav open class (used for transitions)
			navEl.classList.remove(navOpenClass)
		}

		//update the toggles for accessibility
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', false)

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Open Menu')
		})

		//update the closers for accessibility
		this.mobileMenuClosers.forEach(mobileMenuCloser => {
			//set the aria expanded attributes
			mobileMenuCloser.setAttribute('aria-expanded', false)

			//optionally hide
			if (mobileMenuCloser.getAttribute('data-nav-close') === 'hide') {
				mobileMenuCloser.setAttribute('hidden', '')
			}
		})

		//attempt to restore focus
		if (previouslyActiveEl) {
			previouslyActiveEl.focus()
		}
	},
})
nav.close()

// ---------- svg sprite ----------
import './Components/svgSprite'

// ---------- video cover (for background video) ----------
import videoCover from './Components/videoCover'

// ---------- responsive videos ----------
import responsiveVideos from './Components/responsiveElements'

// ---------- lightbox ----------
import MediaLightbox from './Components/MediaLightbox'
let lightbox = new MediaLightbox({
	onHtmlSetUp(el) {
		//get the lightbox container
		let lightboxContainer = el.querySelector('.lightbox-container')

		//add a lightbox header
		let lightboxHeader = document.createElement('div')
		lightboxHeader.classList.add('lightbox-header')
		lightboxHeader.classList.add('block--padded')
		lightboxContainer.insertAdjacentElement('afterbegin', lightboxHeader)

		//add the full reversed logo
		let reversedLogoSelector = '.header-logo--reversedFull'
		let reversedLogo = document.querySelector(reversedLogoSelector)
		if (reversedLogo) {
			lightboxHeader.innerHTML = `<a href="/" class="lightbox-logo">${reversedLogo.outerHTML}</a>`
			reversedLogo = lightboxHeader.querySelector(reversedLogoSelector)
			reversedLogo.removeAttribute('class')
		}

		//customize the close button and add it to the header
		let lightboxClose = el.querySelector('.lightbox-close')
		if (lightboxClose) {
			lightboxClose.classList.add('actionButton')
			lightboxClose.classList.add('actionButton--close')
			lightboxClose.innerHTML =
				'<svg class="icon icon-close" aria-label="Close Icon" role="img"><use href="#close"></use></svg><span class="actionButton-text text--action">Back</span>'
			lightboxHeader.appendChild(lightboxClose)
		}

		let lightboxContent = el.querySelector('.lightbox-content')
		if (lightboxContent) {
			lightboxContent.classList.add('block--fencedWrapper')
			lightboxContent.classList.add('block--padded')
		}
	},
	onOpen() {
		if (holderEl) {
			holderEl.inert = true
		}
		html.classList.add(lightBoxIsOpenClass)
	},
	onClose() {
		if (holderEl) {
			holderEl.inert = false
		}
		html.classList.remove(lightBoxIsOpenClass)
	},
})

// ---------- scrollbar compensation ----------
import scrollbarCompensation from './Components/scrollbarCompensation'
scrollbarCompensation()

// ---------- mobile vh fix ----------
import mobileVh from './Components/mobileVh'
mobileVh()

// ---------- top bar ----------
import topbar from 'topbar'
topbar.config({
	barColors: { 0: 'rgba(207, 181, 44, 1)', 1: 'rgba(207, 181, 44, 1)' },
	shadowBlur: 0,
	shadowColor: 'rgba(0, 0, 0, 0)',
})

// ---------- nav ----------
import updateNav from './Components/updateNav'

// ---------- update the header class ----------
const headerTransparentClass = 'header--transparent'
const headerSolidClass = 'header--solid'
const updateHeaderClass = function () {
	//updates the header class based on the page type
	let page = document.querySelector('.page')
	if (page) {
		if (page.classList.contains('page--hero')) {
			header.classList.add(headerTransparentClass)
			header.classList.remove(headerSolidClass)
		} else {
			header.classList.add(headerSolidClass)
			header.classList.remove(headerTransparentClass)
		}
	}
}

// ---------- on page load ----------
const onPageLoad = function (isInitialLoad = false) {
	// ---------- nav ----------
	updateNav()

	// ---------- header class ----------
	updateHeaderClass()

	// ---------- intro ----------
	let intros = document.querySelectorAll('[data-intro]')
	if (intros.length) {
		import(/* webpackChunkName: "intros" */ './Components/intros').then(({ default: intros }) => {
			intros.init({
				onOpen() {
					openIntroCount += 1
					if (holderEl) {
						holderEl.inert = true
					}
					html.classList.add(introIsOpenClass)
				},
				onClose() {
					openIntroCount -= 1
					if (holderEl) {
						holderEl.inert = false
					}
					if (openIntroCount < 1) {
						html.classList.remove(introIsOpenClass)
					}
				},
			})
		})
	}

	// ---------- sliders ----------
	let sliders = document.querySelectorAll('[data-flickity-slider]')
	if (sliders.length) {
		import(/* webpackChunkName: "sliders", webpackPreload: true */ './Components/flickitySliders').then(
			({ default: flickitySliders }) => {
				flickitySliders.init({ slideSelector: '.slide' })
			},
		)
	}

	// ---------- map ----------
	let maps = document.querySelectorAll('[data-map]')
	if (maps.length) {
		import(/* webpackChunkName: "simpleMaps", webpackPrefetch: true */ './Components/simpleMaps').then(
			({ default: simpleMaps }) => {
				simpleMaps.init()
			},
		)
	}

	// ---------- properties ----------
	let properties = document.querySelectorAll('.properties')
	if (properties.length) {
		import(/* webpackChunkName: "properties", webpackPrefetch: true */ './Components/properties').then(
			({ default: properties }) => {
				properties.init()
			},
		)
	}

	// ---------- responsive videos ----------
	if (!isInitialLoad) {
		responsiveVideos.destroy()
	}
	responsiveVideos.init()

	// ---------- video cover ----------
	if (!isInitialLoad) {
		videoCover.destroy()
	}
	videoCover.init()

	// ---------- lightbox ----------
	lightbox.init()

	// ---------- focus h1 ----------
	if (!isInitialLoad) {
		//attempt to focus the page's h1
		let h1El = document.querySelector('h1')
		if (h1El) {
			h1El.tabIndex = -1
			h1El.focus()
		}
	}

	// ---------- modal ----------
	let modals = document.querySelectorAll('[data-modal]')
	if (modals.length) {
		import(/* webpackChunkName: "modals" */ './Components/modals').then(({ default: modals }) => {
			modals.init({
				onOpen() {
					openModalCount += 1
					if (holderEl) {
						holderEl.inert = true
					}
					html.classList.add(modalIsOpenClass)
				},
				onClose() {
					openModalCount -= 1
					if (holderEl) {
						holderEl.inert = false
					}
					if (openModalCount < 1) {
						html.classList.remove(modalIsOpenClass)
					}
				},
			})
		})
	}
}
onPageLoad(true)

document.addEventListener('pjax:success', () => {
	onPageLoad(false)
})
document.addEventListener('pjax:send', () => {
	// ---------- nav ----------
	nav.close()

	// ---------- lightbox ----------
	lightbox.close()

	// ---------- topbar ----------
	topbar.show()
})

document.addEventListener('pjax:complete', topbar.hide)

document.addEventListener('pjax:error', e => {
	console.log('pjax error', e)
	if (e.hasOwnProperty('request') && (e.request.status === 301 || e.request.status === 302)) {
		window.location.replace(e.request.responseURL)
	}
})

// ---------- pjax ----------
if (!isIE11) {
	import(/*webpackChunkName: "pjax"*/ 'pjax').then(({ default: Pjax }) => {
		new Pjax({
			elements:
				'a[href]:not(.no-pjax):not([href*=".gif"]):not([href*=".jpg"]):not([href*=".png"]):not([href*=".pdf"]):not([href*=".json"]), form[method="get"]',
			selectors: [
				'head > title',
				'meta[name="description"]',
				'meta[name="csrf-name"]',
				'meta[name="csrf-value"]',
				'.main',
			],
			cacheBust: false,
		})
	})
}

// ---------- focus within ----------
focusWithin(document)
