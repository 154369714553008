import ListenerManager from './listenerManager'
import 'web-animations-js/web-animations.min'
import ResponsiveElement from './ResponsiveElement'

export default class Lightbox {
	#baseClass
	#closeSelectorAttr
	#baseEl = null
	#mediaHolderEl = null
	#isOpen = false
	#listenerBoss
	#onClose
	#onHtmlSetUp
	#onOpen
	#elToFocusOnClose = null
	#resizer

	constructor({
		baseClass = 'lightbox',
		closeSelectorAttr = 'data-close',
		onClose = function () {},
		onHtmlSetUp = function (el) {},
		onOpen = function () {},
	} = {}) {
		//assign options
		this.#baseClass = baseClass
		this.#closeSelectorAttr = closeSelectorAttr
		this.#onClose = onClose
		this.#onHtmlSetUp = onHtmlSetUp
		this.#onOpen = onOpen

		//set up the listener manager
		this.#listenerBoss = new ListenerManager()

		//set up the HTML
		this.#setUpHtml()

		//set up the closers
		this.#setUpClosers()
	}

	#setUpHtml() {
		if (this.#baseEl) {
			return
		}

		//the base element
		let el = document.createElement('div')
		el.classList.add(this.#baseClass)
		el.hidden = true
		el.setAttribute('role', 'dialog')

		//inner : el -> inner
		let inner = document.createElement('div')
		inner.classList.add(`${this.#baseClass}-inner`)
		el.appendChild(inner)

		//container : el -> inner -> container
		let container = document.createElement('div')
		container.classList.add(`${this.#baseClass}-container`)
		inner.appendChild(container)

		//close : el -> inner -> container -> close
		let closeButton = document.createElement('button')
		closeButton.setAttribute(this.#closeSelectorAttr, '')
		closeButton.classList.add(`${this.#baseClass}-close`)
		closeButton.innerText = 'x'
		container.appendChild(closeButton)

		//content : el -> inner -> container -> content
		let content = document.createElement('div')
		content.classList.add(`${this.#baseClass}-content`)
		container.appendChild(content)

		let mediaHolder = document.createElement('div')
		mediaHolder.classList.add(`${this.#baseClass}-mediaHolder`)
		content.appendChild(mediaHolder)
		this.#mediaHolderEl = mediaHolder

		//overlay : el -> overlay
		let overlay = document.createElement('div')
		overlay.setAttribute(this.#closeSelectorAttr, '')
		overlay.classList.add(`${this.#baseClass}-overlay`)
		el.appendChild(overlay)

		//add the element to the body
		document.body.appendChild(el)
		this.#baseEl = el

		//callback to allow the HTML to be altered before setting up the events
		this.#onHtmlSetUp(el)
	}

	#setUpClosers() {
		let closers = this.#baseEl.querySelectorAll(`[${this.#closeSelectorAttr}]`)
		if (closers.length) {
			closers.forEach(closer => {
				this.#listenerBoss.register(closer, 'click', () => {
					this.close()
				})
			})
		}
	}

	openMedia(src, type = 'img', { alt = '', width, height } = {}) {
		//make sure we have an image or iframe
		if (['img', 'iframe'].indexOf(type) === -1) {
			console.error('invalid lightbox type')
			return
		}

		//create the element and set the attributes
		let mediaEl = document.createElement(type)
		mediaEl.src = src
		if (width) {
			mediaEl.setAttribute('width', width)
		}
		if (height) {
			mediaEl.setAttribute('height', height)
		}
		if (type === 'img') {
			mediaEl.setAttribute('alt', alt)
		} else if (type === 'iframe') {
			mediaEl.setAttribute('frameborder', '0')
			mediaEl.setAttribute('allowfullscreen', '')
		}

		//empty media holder  el
		this.#mediaHolderEl.textContent = ''

		//add the loading class
		this.#baseEl.classList.remove(`${this.#baseClass}--loaded`)
		this.#baseEl.classList.add(`${this.#baseClass}--loading`)

		//open the lightbox
		this.open()

		//remove the loading class once loaded
		this.#listenerBoss.register(
			mediaEl,
			'load',
			() => {
				this.#baseEl.classList.add(`${this.#baseClass}--loaded`)
				this.#baseEl.classList.remove(`${this.#baseClass}--loading`)

				//focus
				mediaEl.focus()
			},
			{ once: true, passive: true },
		)

		//add to the DOM
		this.#mediaHolderEl.appendChild(mediaEl)

		//make the iframe responsive
		if (type === 'iframe') {
			this.#resizer = new ResponsiveElement(mediaEl, { wrapperClass: `${this.#baseClass}-responsive` })
		}
	}

	open() {
		if (!this.#isOpen) {
			this.#isOpen = true
			this.#baseEl.hidden = false
			this.#baseEl.animate([{ opacity: 0 }, { opacity: 1 }], {
				duration: 500,
				easing: 'ease-out',
			})
			this.#onOpen()

			//focus
			this.#mediaHolderEl.focus()
		}
	}

	close() {
		if (this.#isOpen) {
			this.#isOpen = false
			let closeAnimation = this.#baseEl.animate([{ opacity: 1 }, { opacity: 0 }], {
				duration: 500,
				easing: 'ease-in',
			})
			closeAnimation.addEventListener(
				'finish',
				() => {
					//empty media holder  el
					this.#mediaHolderEl.textContent = ''

					//hide the lightbox el
					this.#baseEl.hidden = true
				},
				{ once: true, passive: true },
			)
			this.#onClose()

			//restore focus
			if (this.#elToFocusOnClose) {
				this.#elToFocusOnClose.focus()
			}
		}
	}

	destroy() {
		if (this.#baseEl) {
			if (this.#isOpen) {
				this.close()
			}
			//empty media holder  el
			this.#mediaHolderEl.textContent = ''
			document.body.removeChild(this.#baseEl)
			this.#baseEl = null
		}
		this.#listenerBoss.removeAll()
	}

	get isOpen() {
		return this.#isOpen
	}

	set returnFocusOnClose(el) {
		this.#elToFocusOnClose = el
	}
}
