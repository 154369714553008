import ListenerManager from './listenerManager'
import { debounce } from 'debounce'

/*
 * Responsive Element
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2020
 */

export default class ResponsiveElement {
	#el = null
	#listenerBoss
	#origRatioAttr = ''
	#origHeightAttr = ''
	#origWidthAttr = ''
	#ratioAttr = ''

	constructor(el, { wrapperClass = 'responsive', ratioAttr = 'data-aspect-ratio' } = {}) {
		if (!el) {
			return
		}

		this.#el = el
		this.#listenerBoss = new ListenerManager()
		this.#ratioAttr = ratioAttr
		this.#origRatioAttr = this.#el.getAttribute(this.#ratioAttr)

		if (!this.#el.hasAttribute(this.#ratioAttr)) {
			//set aspect ratio
			this.#el.setAttribute(this.#ratioAttr, this.#el.offsetHeight / this.#el.offsetWidth)

			//remove any previously set width or height attributes
			this.#origHeightAttr = this.#el.getAttribute('height')
			this.#origWidthAttr = this.#el.getAttribute('width')
			this.#el.removeAttribute('height')
			this.#el.removeAttribute('width')

			//wrap the this.#element in a div with the parent class, if it isn't already wrapped
			if (!this.#el.parentNode || !this.#el.parentNode.classList.contains(wrapperClass)) {
				let wrapperEl = document.createElement('div')
				wrapperEl.classList.add(wrapperClass)
				this.#el.parentNode.insertBefore(wrapperEl, this.#el)
				wrapperEl.appendChild(this.#el)
			}

			//watch
			;['DOMContentLoaded', 'load', 'resize'].forEach(eventName => {
				this.#listenerBoss.register(
					window,
					eventName,
					debounce(() => this.resize(), 50, false),
				)
			})
		}

		this.resize()
	}

	resize() {
		if (this.#el) {
			let newWidth = this.#el.parentNode.offsetWidth
			let newHeight = newWidth * this.#el.getAttribute(this.#ratioAttr)
			newWidth = Math.ceil(newWidth)
			newHeight = Math.floor(newHeight)
			this.#el.style.width = Math.round(newWidth) + 'px'
			this.#el.style.height = Math.round(newHeight) + 'px'
		}
	}

	destroy() {
		if (this.#el) {
			//remove the ratio attribute
			if (this.#origRatioAttr) {
				this.#el.setAttribute(this.#ratioAttr, this.#origRatioAttr)
			} else {
				this.#el.removeAttribute(this.#ratioAttr)
			}

			//restore width and height attributes
			if (this.#origHeightAttr) {
				this.#el.setAttribute('height', this.#origHeightAttr)
			}
			if (this.#origWidthAttr) {
				this.#el.setAttribute('width', this.#origWidthAttr)
			}
		}
		this.#listenerBoss.removeAll()
	}
}
